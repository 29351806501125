import React from "react";

class Support extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="container">
          <h1>This is the support page</h1>
        </div>
      </div>
    );
  }
}

export default Support;
